<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>

      <template v-if="text">
        <v-card-text>{{ text }}</v-card-text>
      </template>
      <template v-else-if="htmltext">
        <v-card-text v-html="htmltext"></v-card-text>
      </template>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="cancel">{{ canceltext }}</v-btn>
        <v-btn color="primary darken-1" text @click="ok">{{ oktext }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String
    },
    htmltext: {
      type: String
    },
    oktext: {
      type: String,
      required: true
    },
    canceltext: {
      type: String,
      required: true
    }
  },
  methods: {
    ok() {
      this.$emit("ok")
    },
    cancel() {
      this.$emit("cancel")
    }
  }
}
</script>